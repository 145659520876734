import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import { Tab, Tabs, Modal, CvCardV2, OpinionCard, CvCard } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import DownloadIcon from "./icons/DownloadIcon";
import CloseIcon from "./icons/CloseIcon";
import classes from "./styles.module.scss";
import History from "../dashboard-offers/single-offer/history";
import ModalContactRequest from "./ModalContactRequest";
import { Mutation } from "react-apollo";
import CREATE_CLIENT_INTEREST from "./mutation";

const avatarClick = () => {};

@inject(
  "appStore",
  "jobSubmissionStore",
  "sessionStore",
  "modalStore",
  "talentStore"
)
@observer
class SingleCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileVisible: false
    };
  }

  getFileType = file => {
    const type = file.substr(file.length - 4);
    return type.toLowerCase();
  };

  displayedName = firstName => {
    const { i18n } = this.props;
    if (firstName) {
      return firstName;
    }
    return i18n._(t`Talent`);
  };

  urlAccess = url => {
    window.open(url, "_blank");
  };

  closeModal = () => {
    const { modalStore } = this.props;
    const { changeIsVisibleContactRequest } = modalStore;
    changeIsVisibleContactRequest(false);
  };

  getExperineceToDisplay = experience => {
    const { i18n } = this.props;
    if (experience != null && experience == 0) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 1) {
      return `${i18n._(t`Junior`)}(0-3)`;
    } else if (experience && experience == 2) {
      return `${i18n._(t`Confirmé`)} (3-7)`;
    } else if (experience && experience == 3) {
      return `${i18n._(t`Senior`)} (7-10)`;
    } else if (experience == 4) {
      return `${i18n._(t`Expert`)} (10+)`;
    } else {
      return `${i18n._(t`Non assigné`)}`;
    }
  };
  replaceWithBr = description => {
    return description ? description.replace(/\n/g, "<br />") : "";
  };
  render() {
    const {
      i18n,
      modalStore,
      jobSubmissionStore,
      talentStore,
      sessionStore,
      appStore,
      source
    } = this.props;
    const { id } = sessionStore;
    const { jobSubmission, reset } = jobSubmissionStore;
    const { resetTalent, talent } = talentStore;
    const {
      changeIsVisible2,
      isVisibleContactRequest,
      changeIsVisibleContactRequest,
      changeIsVisibleListPools
    } = modalStore;
    const activeTab = "1";
    const slugedName =
      talent &&
      talent.firstName &&
      talent.firstName
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    const BhId = talent?.id
      ? talent?.id?.toString()
      : talent?.talentID.toString();
    const link = slugedName + "-" + BhId;
    const clientID = Number(id);
    const h =
      (talent && (source !== "Cockpit" || talent.cvPath.length > 1)) ||
      jobSubmission
        ? window.innerHeight
        : "max-content";
    const { width } = appStore;
    const isMobile = width <= 830 ? true : false;
    const cvUrl =
      source !== "Cockpit" && talent && !talent.cvUpdated
        ? talent?.candidateCv
        : talent?.cvPath;
    return (
      <div
        className={classes.content}
        style={{
          height: h,
          overflow: "scroll",
          position:
            (talent && (source !== "Cockpit" || talent.cvPath.length > 1)) ||
            jobSubmission
              ? "relative"
              : "fixed",
          width:
            (talent && (source !== "Cockpit" || talent.cvPath.length > 1)) ||
            jobSubmission
              ? "88%"
              : "72%",
          top:
            (talent && (source !== "Cockpit" || talent.cvPath.length > 1)) ||
            jobSubmission
              ? "0%"
              : "20%"
        }}
      >
        {isVisibleContactRequest && (
          <Modal
            isVisible={isVisibleContactRequest}
            withCloseButton={false}
            position="fixed"
          >
            <div
              style={{
                width: "35%",
                margin: "auto",
                position: "fixed",
                top: "22%",
                left: "27%"
              }}
            >
              <ModalContactRequest
                close={this.closeModal}
                candidateBhId={
                  talent && talent.talentID
                    ? talent.talentID
                    : jobSubmission.candidateID
                }
              />
            </div>
          </Modal>
        )}
        <div
          style={{
            width: "20px",
            float: "right",
            marginBottom: "10px",
            cursor: "pointer"
          }}
          onClick={() => {
            reset();
            resetTalent();
            changeIsVisible2(false);
          }}
        >
          <CloseIcon />
        </div>
        <Mutation
          mutation={CREATE_CLIENT_INTEREST}
          variables={{
            clientId: clientID,
            candidateBhId:
              talent && talent.talentID
                ? talent.talentID
                : jobSubmission && jobSubmission.candidateID
          }}
          onCompleted={this.mutationCompleted}
          onError={errors => {
            errors.graphQLErrors.forEach(({ message }) => {
              if (message) {
                this.onErrorHandler(message);
              }
            });
          }}
        >
          {(mutation, { loading }) => (
            <div style={{ marginTop: "30px" }}>
              {jobSubmission && (
                <CvCard
                  candidate={{
                    id: jobSubmission.candidateID,
                    avatar: "/defaultAvatar.webp",
                    name: this.displayedName(
                      jobSubmission.candidatefirstName,
                      jobSubmission.candidatelastName
                    ),
                    position: jobSubmission.candidateOccupation,
                    location:
                      jobSubmission.candidateAddress.city ||
                      jobSubmission.candidateAddress.address1 ||
                      "N/A",
                    disponibility: `${i18n._(t`Vérifier la disponibilité`)}`,
                    experience: `${i18n._(t`indéterminé`)}`,
                    payRate: jobSubmission.payRate
                      ? `${jobSubmission.payRate} ${i18n._(t`euros / jour`)}`
                      : i18n._(t`indéterminé`)
                  }}
                  disponibilityClick={() => {
                    changeIsVisibleContactRequest(true);
                    mutation();
                  }}
                  buttonLabel={i18n._(t`Ajouter à un vivier`)}
                  buttonClick={() => {
                    changeIsVisibleListPools(true);
                    talentStore.changeTalentID(jobSubmission.candidateID);
                  }}
                />
              )}

              {talent && (
                <CvCardV2
                  candidate={{
                    id: talent.id,
                    avatar: talent.profilePhotoURL
                      ? talent.profilePhotoURL
                      : "/defaultAvatar.webp",
                    name: this.displayedName(talent.firstName),
                    position: talent.occupation,
                    profile: `${i18n._(t`Voir le profil complet`)}`,
                    location: talent.address
                      ? talent.address.city ||
                        talent.address.address1 ||
                        talent.address.countryName ||
                        "N/A"
                      : "N/A",
                    disponibility: `${i18n._(t`Vérifier la disponibilité`)}`,
                    experience: this.getExperineceToDisplay(talent.experience),
                    payRate: `${talent.payRate} ${i18n._(t`euros / jour`)}`,
                    accessible: talent.publicProfil && !talent.isDeleted,
                    isDeleted: !talent.isDeleted
                  }}
                  buttonLabel={i18n._(t`Ajouter à un vivier`)}
                  buttonClick={() => {
                    changeIsVisibleListPools(true);
                    talentStore.changeTalentID(talent.id);
                  }}
                  profileClick={() => {
                    this.urlAccess(`/talent/${link}`);
                  }}
                  disponibilityClick={() => {
                    !talent.isDeleted && changeIsVisibleContactRequest(true);
                    !talent.isDeleted && mutation();
                  }}
                />
              )}
            </div>
          )}
        </Mutation>

        {talent && (source !== "Cockpit" || talent.cvPath.length > 1) && (
          <Tabs
            defaultActiveTab={activeTab}
            onChange={this.changeTabHandler}
            type="transparentV2"
            className={classes.tabsChange}
            isMobile={isMobile}
          >
            <Tab name="1" tab={i18n._(t`CV`)}>
              {talent && (talent.candidateCv || talent.cvPath) && (
                <div>
                  {isMobile ? (
                    <div>
                      <div className={classes.openCv}>
                        <a
                          href={cvUrl}
                          target="_blank"
                          download="cv"
                          style={{ color: "#fa324a" }}
                        >
                          <Trans> Ouvrir le CV en PDF </Trans>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.getFileType(cvUrl) === ".pdf" ? (
                        <iframe src={cvUrl} width="100%" height="800px">
                          <p>Sorry, PDF cannot be displayed.</p>
                        </iframe>
                      ) : (
                        <div>
                          <div
                            style={{
                              float: "right",
                              marginRight: "30px",
                              marginBottom: "15px"
                            }}
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              href={cvUrl}
                              download="cv"
                            >
                              <DownloadIcon />
                            </a>
                          </div>

                          <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${cvUrl}`}
                            width="100%"
                            height="800px"
                            frameBorder="0"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Tab>
            {/*
              <Tab name="2" tab={i18n._(t`Brief profil`)}>
              <div
                className={classes.lineBreaks}
                style={{ position: "relative", width: "100%" }}
              >
                <br />
                {talent && talent.comments ? (
                  <OpinionCard
                    avatar="/defaultAvatar.png"
                    title={
                      talent.comments.commenter || "Talent Community Specialist"
                    }
                    subTitle="Talent Community Specialist"
                    description={this.replaceWithBr(talent.comments.comment)}
                    avatarClick={avatarClick}
                  />
                ) : (
                  <div>{i18n._(t`Pas de commentaires pour ce talent!`)}</div>
                )}
                <br />
              </div>
            </Tab>
            <Tab name="3" tab={i18n._(t`Références`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {talent && talent.reference ? (
                  talent.reference.map(ref => {
                    if (ref)
                      return (
                        <div key={ref.id}>
                          <OpinionCard
                            avatar="/defaultAvatar.png"
                            title={`${ref.referenceFirstName} ${ref.referenceLastName}`}
                            subTitle={ref.referenceTitle}
                            description={ref.outcome}
                          />
                          <br />
                        </div>
                      );
                    return null;
                  })
                ) : (
                  <div>{i18n._(t`Pas de référence pour ce talent!`)}</div>
                )}
              </div>
            </Tab>
            <Tab name="4" tab={i18n._(t`Avis équipe`)}>
              <div>{i18n._(t`Pas d'avis équipe pour ce talent!`)}</div>
              {/* <History idOfCandidate={jobSubmission.candidateID} /> }
            </Tab> 
              */}
          </Tabs>
        )}
        {jobSubmission && (
          <Tabs
            className={classes.tabsChange}
            defaultActiveTab={activeTab}
            onChange={this.changeTabHandler}
            type="transparentV2"
            isMobile={isMobile}
          >
            <Tab name="1" tab={i18n._(t`CV`)}>
              {jobSubmission && jobSubmission.candidateCv && (
                <div>
                  {isMobile ? (
                    <div>
                      <div className={classes.openCv}>
                        <a
                          href={jobSubmission.candidateCv}
                          target="_blank"
                          download="cv"
                          style={{ color: "#fa324a" }}
                        >
                          <Trans> Ouvrir le CV en PDF </Trans>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.getFileType(jobSubmission.candidateCv) ===
                      ".pdf" ? (
                        <iframe
                          src={jobSubmission.candidateCv}
                          width="100%"
                          height="800px"
                        >
                          <p>Sorry, PDF cannot be displayed.</p>
                        </iframe>
                      ) : (
                        <div>
                          <div
                            style={{
                              float: "right",
                              marginRight: "30px",
                              marginBottom: "15px"
                            }}
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              href={jobSubmission.candidateCv}
                              download="cv"
                            >
                              <DownloadIcon />
                            </a>
                          </div>

                          <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${jobSubmission.candidateCv}`}
                            width="100%"
                            height="800px"
                            frameBorder="0"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Tab>
            <Tab name="2" tab={i18n._(t`Brief profil`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {jobSubmission && jobSubmission.comment && (
                  <OpinionCard
                    avatar="/defaultAvatar.webp"
                    title={
                      (jobSubmission && jobSubmission.commentingSourcer) ||
                      i18n._(t`CF Sourceur`)
                    }
                    subTitle={i18n._(t`Talent Community Specialist`)}
                    description={this.replaceWithBr(jobSubmission.comment)}
                    avatarClick={avatarClick}
                  />
                )}
                <br />
              </div>
            </Tab>
            <Tab name="3" tab={i18n._(t`Références`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {jobSubmission &&
                  jobSubmission.reference &&
                  jobSubmission.reference.map(ref => {
                    if (ref)
                      return (
                        <div key={ref.id}>
                          <OpinionCard
                            title={`${ref.referenceFirstName} ${ref.referenceLastName}`}
                            subTitle={ref.referenceTitle}
                            description={ref.outcome}
                          />
                          <br />
                        </div>
                      );
                    return null;
                  })}
              </div>
            </Tab>
            <Tab name="4" tab={i18n._(t`Historique`)}>
              <History idOfCandidate={jobSubmission.candidateID} />
            </Tab>
          </Tabs>
        )}
      </div>
    );
  }
}

SingleCandidate.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible2: PropTypes.func,
    changeIsVisibleContactRequest: PropTypes.func,
    isVisibleContactRequest: PropTypes.bool
  }).isRequired,
  sessionStore: PropTypes.shape({}).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    resetTalent: PropTypes.func,
    changeTalentID: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    reset: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired
};

export default withI18n()(withRouter(SingleCandidate));
